<template>
    <div class="coursePersonnel-list">
        <div class="manage-wrapper">
            <div class="table-wrapper">
                <h2 class="table-title">
                    <img src="../../assets/images/main/bulltin-icon.png" alt="" class="title-icon">观思问管理</h2>
                <el-form :inline="true" :model="formInline" class="demo-form-inline flex-align-between">
                    <div class="formInline-left flex-layout">
                        <!-- <el-form-item label="行为 :">
                            <el-select v-model="formInline.activityType" placeholder="选择行为" style="width: 230px;">
                                <el-option label="全部" value="">全部</el-option>
                                <el-option label="大赛类" value="0"></el-option>
                                <el-option label="教研类" value="1"></el-option>
                                <el-option label="合作学习墙" value="2"></el-option>
                            </el-select>
                        </el-form-item> -->
                        <!-- <el-form-item label="阶段 :">
                            <el-select v-model="formInline.activityType" placeholder="选择阶段" style="width: 230px;">
                                <el-option label="全部" value="">全部</el-option>
                                <el-option label="大赛类" value="0"></el-option>
                                <el-option label="教研类" value="1"></el-option>
                                <el-option label="合作学习墙" value="2"></el-option>
                            </el-select>
                        </el-form-item> -->
                    </div>
                    <div class="formInline-right" @click="exportFile">
                        <div class="export-btn csp" style="margin-right:10px"><i class="export-icon"></i>导出
                        </div>
                    </div>
                </el-form>
                <div class="table">
                    <el-table :data="tableData" border style="width: 100%">
                        <el-table-column type="index" label="序号" style="background:#2373EC" align="center">
                        </el-table-column>
                        <el-table-column prop="name" label="行为" align="center">
                            <template slot-scope="scope">
                                {{titleType[scope.row.titleType]}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="content" label="内容" align="center"></el-table-column>
                        <el-table-column prop="userAreaName" label="所属阶段" align="center" show-overflow-tooltip>
                            <template slot-scope="scope">
                                {{markList[scope.row.mark]}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="questTime" label="内容时间" align="center" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column prop="createTime" :label="'提交时间'" align="center">
                            <!-- <template slot-scope="scope">
                                {{scope.row.recordTime?scope.row.recordTime:0}}
                            </template> -->
                        </el-table-column>
                    </el-table>
                    <pagination v-show="pages.total > 0" :total="pages.total" :page.sync="pages.currentPage"
                        :limit.sync="pages.pageSize" @pagination="handlePageChange">
                    </pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
    export default {
        data() {
            return {
                id: 89,
                pages: { //分页
                    currentPage: 1,
                    pageSize: 10,
                    total: 0,
                },
                tableData: [],
                formInline: {},
                titleType:{
                    0:'观',
                    1:'思',
                    2:'问',
                    3:'听'
                },
                markList:{
                    1:'集中培训',
                    2:'跟岗研修',
                    3:'返岗实践'
                }
            }
        },

        methods: {
            handlePageChange(data) {
                this.pages.currentPage = data.pageIndex
                this.pages.pageSize = data.pageSize
                this.getUserStudyInfo();
            },
            async getUserStudyInfo() {
                let params = {
                    projectId: this.$store.state.user.currentProject.id,
                    page: this.pages.currentPage,
                    limit: this.pages.pageSize,
                    userId: this.$route.params.id,
                }
                let resData = await this.$Api.kimi.userTrainingList(params);
                console.log(resData);
                this.tableData = resData.data.records
                this.pages.total = resData.data.total
            },
            async exportFile() {
                this.$message.info('正在导出...')
                window.location.href = encodeURI(axios.defaults.baseURL +`/course/phase/export?projectId=${this.$store.state.user.currentProject.id}&userId=${this.$route.params.id}`)
                // window.location.href =
                //     `${this.commonbaseURL}/score/projectHome/getUserStudyInfo/export?page=${this.pages.currentPage}&limit=${this.pages.pageSize}&lessonId=${this.$route.params.id}`;
            },
        },
        mounted() {
            this.getUserStudyInfo();
        }
    }
</script>

<style lang="less" scoped>
    @import "../manage/less/table.less";

    .el-form--inline .formInline-right {
        justify-content: flex-end;
    }

    .coursePersonnel-list {

        .demo-form-inline {
            margin-bottom: 20px;
        }

        .table-wrapper {
            border-radius: 8px;
            padding-top: 10px;
            background: #fff;
            padding: 0 26px 24px;
            box-shadow: 0px 2px 10px 0px #F5F8FA;
        }

        .table-title {
            line-height: 68px;
            font-size: 17px;
        }

        // .export-btn {
        //     color: #595959;
        //     display: flex;
        //     justify-content: flex-end;
        //     margin-bottom: 10px;
        // }

        .export-icon {
            display: inline-block;
            background-image: url("~@/assets/images/admin/export.png");
            background-size: 100% 100%;
            width: 17px;
            height: 16px;
            margin-right: 8px;
            vertical-align: -2px;
        }

        .title-icon {
            width: 21px;
            height: 21px;
            margin-right: 9px;
            vertical-align: -4px;
        }

        .table {
            /deep/.el-table th {
                &>.cell {
                    white-space: pre-line;
                }
            }
        }

        @media screen and (min-width:1250px) {
            .table-wrapper {
                padding: 0 43px 24px;
            }
        }
    }
</style>
